import React from "react";
import { graphql } from "gatsby";
import _ from "lodash";
import Seo from "gatsby-plugin-wpgraphql-seo";
import { IntlProvider } from "react-intl";

import SeoStructure from "../components/seo-structure";
import Layout from "../components/layout";

import LocalesContext from "../context/LocalesContext";

const PageTemplate = ({ data }) => {
  if (_.isEmpty(data.content)) return null;

  return (
    <>
      <SeoStructure
        title={data.title}
        description="Page template"
        slug={data.originalSlug}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: `${data.title}`,
              },
            ],
          })}
        </script>
      </SeoStructure>
      <Seo post={data.seo} />
      <div className="wrapper-single-page">
        <h1>{data.title}</h1>
        <div
          className="single-page-content"
          dangerouslySetInnerHTML={{
            __html: data.content,
          }}
        />
      </div>
    </>
  );
};

const PagesTemplate = ({ data, pageContext }) => {
  const avaiablePageTranslations = data.avaiablePostTranslations.nodes;
  const avaiableLangs = avaiablePageTranslations.map(
    (post) => post.language.slug
  );

  return (
    <IntlProvider locale={pageContext.locale} messages={pageContext.messages}>
      <LocalesContext.Provider
        value={{
          slug: pageContext.originalSlug,
          avaiableLangs: avaiableLangs,
          pageNumber: 0,
        }}
      >
        <Layout>
          <PageTemplate data={data.page} />
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default PagesTemplate;

export const query = graphql`
  query ($id: String!, $originalSlug: String!) {
    avaiablePostTranslations: allWpPage(
      filter: { originalSlug: { eq: $originalSlug } }
    ) {
      nodes {
        language {
          slug
        }
      }
    }

    page: wpPage(id: { eq: $id }) {
      nodeType
      title
      uri
      content
      originalSlug
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`;
