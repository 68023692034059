import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from "react-intl";

const SeoStructure = ({ title, description, slug }) => {
  const intl = useIntl();
  const language = intl.locale;

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
        id
      }
    }
  `);

  return (
      <Helmet
          htmlAttributes={{ lang: `en` }}
          titleTemplate={`${title} | ${data.site.siteMetadata.title}`}
      >
         <title>{title}</title>
        <meta
            name='description'
            content={description || data.site.siteMetadata.description}
        />
        <link rel='canonical' href={`${data.site.siteMetadata.siteUrl}${slug}`} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@iam_timsmith' />
        <meta name='og:title' content={title} />
        <meta
            name='og:description'
            content={description || data.site.siteMetadata.description}
        />
        <meta name='og:type' content='website' />
        <meta
            name='og:url'
            content={`${data.site.siteMetadata.siteUrl}/${slug}`}
        />
        <meta name='og:site_name' content={data.site.siteMetadata.title} />
        <script type="application/ld+json">{
          JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            '@id': `${!data.site.id ? '' : data.site.id}`,
            'name': `${!process.env.SITE_NAME ? '' : process.env.SITE_NAME}`,
            'url': `${!process.env.SITE_URL ? '' : process.env.SITE_URL}`,
            'inLanguage': `${!language ? '' : language}`,
            'isPartOf': {
              '@type': 'WebPage',
              '@id': `${!data.site.id ? '' : data.site.id}`,
              'url': `${!process.env.SITE_URL ? '' : process.env.SITE_URL}`,
              'name': `${!process.env.SITE_NAME ? '' : process.env.SITE_NAME}`,
              'description': `${!data.site.siteMetadata.description ? '' : data.site.siteMetadata.description}`,
              'inLanguage': `${!language ? '' : language}`,
            },
            'potentialAction': {
              '@type': 'ReadAction',
              'target': {
                '@type': 'EntryPoint',
                'urlTemplate': `${!process.env.SITE_URL ? '' : process.env.SITE_URL}`
              }
            }
          })}
        </script>
      </Helmet>
  );
};

SeoStructure.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
  children: PropTypes.node,
};

export default SeoStructure;